import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { orderAPI } from "../api/orderAPI";

// async thunk request to get All user
export const getAllUserOrderRequest = createAsyncThunk(
  "orders/getAllUserOrderRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.all();
      dispatch(setAllOrders(data.orders));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleUserOrderRequest = createAsyncThunk(
  "orders/getSingleUserOrderRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.single(dt);
      dispatch(setsingleUserOrders(data.orders));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// To get All Token status

// async thunk request to get All user
export const getAllTokenStatusRequest = createAsyncThunk(
  "orders/getAllTokenStatusRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.tokens();
      dispatch(setAllTokens(data.tokens));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All user
export const getFundsAllRequest = createAsyncThunk(
  "orders/getFundsAllRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.funds();
      dispatch(setFunds(data.funds));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allOrders: [],
  funds: 0,
  tokens: [],
  singleUserOrders: [],
  errorInOrder: "",
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },
    setFunds: (state, action) => {
      state.funds = action.payload;
    },
    setAllTokens: (state, action) => {
      state.tokens = action.payload;
    },
    setsingleUserOrders: (state, action) => {
      state.singleUserOrders = action.payload;
    },
    clearHasError: (state) => {
      state.errorInOrder = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllUserOrderRequest.rejected, (state, action) => {
      state.errorInOrder = action.payload;
    });
    builder.addCase(getSingleUserOrderRequest.rejected, (state, action) => {
      state.errorInOrder = action.payload;
    });
  },
});
export const {
  setAllOrders,
  setsingleUserOrders,
  clearHasError,
  setAllTokens,
  setFunds,
} = orderSlice.actions;

export default orderSlice.reducer;
