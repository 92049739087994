import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./features/appSlice";
import adminReducer from "./features/adminSlice";
import buySellReducer from "./features/buySellSlice";
import clientReducer from "./features/clientSlice";
import fundsReducer from "./features/fundSlice";
import orderReducer from "./features/orderSlice";
import positionReducer from "./features/positionSlice";
import holdingReducer from "./features/holdingSlice";
import watchListReducer from "./features/watchListSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    admin: adminReducer,
    buysell: buySellReducer,
    clients: clientReducer,
    funds: fundsReducer,
    orders: orderReducer,
    positions: positionReducer,
    holdings: holdingReducer,
    watchList: watchListReducer,
  },
  devTools: true,
});
