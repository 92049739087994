import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function SideBar({ sidebarItems }) {
  const location = useLocation();
  const user = useSelector((state) => state.admin.adminDetails);

  return (
    <div className="sticky flex-shrink-0 h-screen overflow-y-auto top-4">
      <nav className="flex flex-col w-64 h-full p-6 space-y-6 rounded-lg">
        {/* Sidebar Header */}
        <button className="p-2.5 text-center text-base font-medium focus:outline-none text-gray-900 hover:text-gray-500 flex w-full cursor-pointer rounded-lg items-center space-x-1 h-10 px-0 py-0 justify-between">
          {/* Your logo or icon goes here */}
          <div className="flex items-center w-full space-x-2">
            <svg
              viewBox="0 0 200 200"
              className="flex-shrink-0 text-yellow-400 rounded-md h-9 w-9"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M200 0H0V200H70.3297L78.2679 115.944H77.5265C70.9978 115.945 65.5978 113.605 60.6286 109.345C55.6595 105.084 52.3617 99.1813 51.3269 92.6953L66.7172 82.6848V82.5605C66.7167 75.7379 69.3342 69.1785 74.0238 64.2506C78.7133 59.3227 85.1127 56.4069 91.8871 56.1114V56.0838H96.4634V39.9676C99.7079 39.9676 102.847 41.1237 105.325 43.231C107.803 45.3382 109.46 48.2603 110 51.4792H110.192V39.9676C112.631 39.9676 115.026 40.6213 117.131 41.8615C119.235 43.1016 120.973 44.8834 122.166 47.0236C123.359 49.1638 123.964 51.5852 123.919 54.0386C123.874 56.4921 123.179 58.8891 121.908 60.9832C126.042 63.7014 129.437 67.4136 131.786 71.784C134.135 76.1545 135.364 81.0453 135.362 86.014V190.194C135.362 191.721 135.965 193.185 137.038 194.264C138.111 195.343 139.566 195.95 141.083 195.95H200V0Z"
                fill="currentColor"
              />
              <mask
                id="mask0_1_3"
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={200}
                height={200}
                style={{ maskType: "alpha" }}
              >
                <path
                  d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
                  fill="currentColor"
                />
              </mask>
              <g mask="url(#mask0_1_3)">
                <path
                  d="M80.5537 84.5046C80.5548 83.695 80.8769 82.9189 81.4493 82.3464C82.0216 81.7739 82.7976 81.4518 83.607 81.4506H92.4959C92.4959 83.0717 91.8521 84.6264 90.706 85.7727C89.56 86.919 88.0056 87.563 86.3848 87.563H80.2737L80.5537 84.5046Z"
                  fill="currentColor"
                />
              </g>
            </svg>

            <div className="w-full text-base text-left">
              <div className="font-semibold text-gray-900">{user?.name}</div>
              <div className="font-medium text-gray-500">@{user?.userId}</div>
            </div>
          </div>
          {/* Arrow icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
        </button>

        {/* Sidebar Items */}
        <div className="flex flex-col space-y-0">
          {sidebarItems.map((item, index) => (
            <div key={index} className="relative flex flex-col">
              <div className="relative">
                <Link to={item?.link}>
                  <button
                    className={`items-center space-x-2 mb-3 p-2.5 text-center text-base font-medium text-gray-900 focus:outline-none hover:text-gray-900  flex w-full justify-start h-10 rounded-lg ${
                      location.pathname.includes(item.link) ? "bg-zinc-100" : ""
                    }`}
                  >
                    {item.icon && item.icon}
                    <span className="flex justify-start flex-grow w-full truncate">
                      {item.name}
                    </span>
                  </button>
                </Link>
              </div>

              {/* Subitems */}
              {item.subItems && item.subItems.length > 0 && (
                <div className="pl-6">
                  {item.subItems.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.link}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {subItem.icon && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px] mr-2"
                        >
                          {/* Your subitem icon path goes here */}
                        </svg>
                      )}
                      {subItem.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>
    </div>
  );
}

export default SideBar;
