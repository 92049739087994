import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { clientAPI } from "../api/clientAPI";
import { appLoading, setMessage } from "./appSlice";

// async thunk request to Register New Payment Method
export const addNewClientRequest = // eslint-disable-next-line
  createAsyncThunk(
    "clients/addNewClientRequest",
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        dispatch(appLoading(true));
        const reg = await clientAPI.registerNew(dataToSend);
        dispatch(setMessage(reg.message));
        dispatch(appLoading(false));
      } catch (error) {
        if (error.response) {
          dispatch(appLoading(false));
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

// async thunk request to get All clients Requests
export const getAllClientsRequest = createAsyncThunk(
  "clients/getAllClientsRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await clientAPI.all();
      dispatch(setallClients(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All Symbols
export const getAllSymbolsRequest = createAsyncThunk(
  "clients/getAllSymbolsRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.allSymbols();
      dispatch(setAllSymbols(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All Symbols
export const getHoldingSymbolsRequest = createAsyncThunk(
  "clients/getHoldingSymbolsRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.holdingSymbols();
      dispatch(setAllHoldingSymbols(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All Blog Requests
export const getAllInactiveClientRequest = createAsyncThunk(
  "clients/getAllInactiveClientRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.allInActive();
      dispatch(setAllInActiveClients(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All Blog Requests
export const getActiveClientRequest = createAsyncThunk(
  "clients/getActiveClientRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.allActive();
      dispatch(setAllActiveClients(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single Blog
export const getSingleClientRequest = createAsyncThunk(
  "clients/getSingleClientRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.singleClient(dt);
      dispatch(setSingleClient(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateClientRequest
export const updateClientRequest = createAsyncThunk(
  "clients/updateClientRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await clientAPI.updateClient(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to totpLoginRequest
export const totpLoginRequest = createAsyncThunk(
  "clients/totpLoginRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await clientAPI.totp(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteClientRequest
export const deleteClientRequest = createAsyncThunk(
  "clients/deleteClientRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await clientAPI.deleteBlog(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allClients: [],
  symbols: [],
  holdingSymbols: [],
  inactive: [],
  active: [],
  singleClient: {},
  errorInClient: "",
};

export const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setallClients: (state, action) => {
      state.allClients = action.payload;
    },
    setAllSymbols: (state, action) => {
      state.symbols = action.payload;
    },
    setAllHoldingSymbols: (state, action) => {
      state.holdingSymbols = action.payload;
    },
    setAllActiveClients: (state, action) => {
      state.active = action.payload;
    },
    setAllInActiveClients: (state, action) => {
      state.inactive = action.payload;
    },
    setSingleClient: (state, action) => {
      state.singleClient = action.payload;
    },
    clearHasError: (state) => {
      state.errorInClient = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNewClientRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getAllClientsRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getSingleClientRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(updateClientRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(deleteClientRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getAllInactiveClientRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getActiveClientRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getAllSymbolsRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getHoldingSymbolsRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(totpLoginRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
  },
});
export const {
  setallClients,
  setSingleClient,
  setAllSymbols,
  clearHasError,
  setAllActiveClients,
  setAllInActiveClients,
  setAllHoldingSymbols,
} = clientSlice.actions;

export default clientSlice.reducer;
