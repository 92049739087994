import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { holdingAPI } from "../api/holdingAPI";

// async thunk request to get All user
export const getAllUsersHoldingRequest = createAsyncThunk(
  "holdings/getAllUsersHoldingRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await holdingAPI.all();
      dispatch(setAllHoldings(data.holdings));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleUserHoldingRequest = createAsyncThunk(
  "holdings/getSingleUserHoldingRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await holdingAPI.single(dt);
      dispatch(setSingleHoldings(data.holdings));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allHoldings: [],
  singleUserHoldings: [],
  errorInHolding: "",
};

export const holdingSlice = createSlice({
  name: "holdings",
  initialState,
  reducers: {
    setAllHoldings: (state, action) => {
      state.allHoldings = action.payload;
    },
    setSingleHoldings: (state, action) => {
      state.singleUserHoldings = action.payload;
    },
    clearHasError: (state) => {
      state.errorInHolding = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllUsersHoldingRequest.rejected, (state, action) => {
      state.errorInHolding = action.payload;
    });
    builder.addCase(getSingleUserHoldingRequest.rejected, (state, action) => {
      state.errorInHolding = action.payload;
    });
  },
});
export const { setAllHoldings, setSingleHoldings, clearHasError } =
  holdingSlice.actions;

export default holdingSlice.reducer;
