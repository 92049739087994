import Portal from "./Portal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { MdWarning } from "react-icons/md";

export default function MessageModal({
  open,
  clickFunction,
  error,
  text,
  buttonText,
  buttonFunction,
}) {
  return (
    <>
      {open && (
        <Portal selector="#modal">
          <div className="fixed top-0 bottom-0 left-0 right-0 z-50 overflow-hidden bg-slate-800 bg-opacity-90">
            <div className="bg-white bg-opacity-100 absolute rounded-md lg:top-[25%] lg:bottom-[25%] lg:left-[34%] lg:right-[34%] top-[25%] bottom-[25%] left-[5%] right-[5%]">
              <div className="flex items-center justify-between px-4 py-4 border-b border-slate-300">
                <p className="text-sm font-semibold text-gray-600">
                  Notification
                </p>
                <AiOutlineCloseCircle
                  onClick={() => clickFunction()}
                  className="text-2xl text-indigo-500 cursor-pointer hover:text-red-500"
                />
              </div>
              <div className="flex justify-center w-full p-6 text-center">
                {!error && (
                  <FaCheckCircle className={`text-6xl text-green-600 `} />
                )}
                {error && <MdWarning className={`text-6xl text-red-500 `} />}
              </div>
              <p className="px-4 text-sm font-semibold text-center text-gray-800 ">
                {text}
              </p>
              {buttonText && (
                <div className="p-4">
                  <button
                    type="submit"
                    onClick={buttonFunction}
                    className="w-full px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-400"
                  >
                    {buttonText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}
