import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserRequest,
  setUserDetail,
  updateNomineeRequest,
} from "../features/adminSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../features/appSlice";
import { clearHasError } from "../features/adminSlice";
import { adminAPI } from "../api/adminAPI";
import SuccessModal from "../components/modals/SuccessModal";

const Configuration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [linkArray, setLinkArray] = useState([]);
  const successMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.admin.hasError);
  const { tradeUrlList } = useSelector((state) => state.admin.adminDetails);

  const handleSubmit = (values) => {
    dispatch(updateNomineeRequest(values));
  };

  const successAndRedirect = () => {
    localStorage.removeItem("alpadmToken");
    dispatch(setMessage(""));
    navigate("/");
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  useEffect(() => {
    dispatch(getUserRequest());
    async function getDetail() {
      const data = await adminAPI.getCpUser();
      dispatch(setUserDetail(data));
      setLinkArray(data.tradeUrlList);
    }
    getDetail();
  }, [dispatch]);

  return (
    <div className="bg-white rounded-md ">
      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />
      <h1 className="py-2 font-semibold text-gray-800">Trade URL list</h1>
      {tradeUrlList &&
        tradeUrlList.length > 0 &&
        tradeUrlList.map((one, index) => {
          return (
            <a
              target="_blank"
              and
              rel="noopener noreferrer"
              href={one}
              className="text-sm text-blue-500"
              key={index}
            >
              {one}
            </a>
          );
        })}
      {!show && (
        <div className="my-2 mt-4">
          <button
            onClick={() => setShow(true)}
            className="px-4 py-2 text-sm font-semibold text-center rounded-lg disabled:bg-gray-300 hover:bg-theme-yellow/80 bg-theme-yellow"
          >
            Update Trade URL List
          </button>
        </div>
      )}
      {show && (
        <h2 className="py-2 font-semibold text-gray-800">
          Update Configuration
        </h2>
      )}
      {show && (
        <StringArrayComponent
          linkArray={linkArray}
          setLinkArray={setLinkArray}
          existedUrlList={tradeUrlList}
        />
      )}
    </div>
  );
};

export default Configuration;

const StringArrayComponent = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddString = () => {
    if (
      inputValue.trim() !== "" &&
      inputValue.includes("https://chartink.com") &&
      !props.linkArray.includes(inputValue)
    ) {
      props.setLinkArray((prevArray) => [...prevArray, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleEditString = (index) => {
    setEditIndex(index);
    setInputValue(props.linkArray[index]);
  };

  const handleSaveEdit = () => {
    if (editIndex !== null && inputValue.trim() !== "") {
      const updatedArray = [...props.linkArray];
      updatedArray[editIndex] = inputValue.trim();
      props.setLinkArray(updatedArray);
      setEditIndex(null);
      setInputValue("");
    }
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setInputValue("");
  };

  const handleDeleteString = (index) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this string?"
    );
    if (confirmed) {
      const updatedArray = props.linkArray.filter((_, i) => i !== index);
      props.setLinkArray(updatedArray);
      setEditIndex(null);
    }
  };

  const handleApiSubmit = () => {
    // For example, using fetch or a library like axios
    console.log("Submitting to API:", props.linkArray);
  };

  return (
    <div className="my-2 space-y-4">
      <div className="flex space-x-2">
        <input
          type="text"
          className="w-1/2 p-2 border border-gray-300"
          placeholder="Enter Chartink Link here (https://chartink.com/*)"
          value={inputValue}
          onChange={handleInputChange}
        />
        {editIndex !== null ? (
          <>
            <button
              className="p-2 text-white bg-green-500 rounded"
              onClick={handleSaveEdit}
            >
              Save
            </button>
            <button
              className="p-2 text-white bg-gray-500 rounded"
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            className="p-2 text-white bg-blue-500 rounded"
            onClick={handleAddString}
          >
            Add
          </button>
        )}
      </div>

      <div>
        <h2 className="mb-2 text-sm font-semibold">
          Updated URL List will be as below:
        </h2>
        <ul>
          {props.linkArray &&
            props.linkArray.length > 0 &&
            props.linkArray.map((item, index) => (
              <li key={index} className="ml-4 list-disc">
                {item}
                <button
                  className="ml-2 text-sm text-blue-500"
                  onClick={() => handleEditString(index)}
                >
                  Edit
                </button>
                <button
                  className="ml-2 text-sm text-red-500"
                  onClick={() => handleDeleteString(index)}
                >
                  Delete
                </button>
              </li>
            ))}
        </ul>
      </div>
      {console.log(
        props.existedUrlList?.length,
        props.linkArray?.length,
        "props.existedUrlList?.length , props.linkArray?.length"
      )}
      <button
        disabled={props.existedUrlList?.length === props.linkArray?.length}
        className="p-2 text-sm text-white bg-green-500 rounded disabled:text-gray-900 disabled:bg-gray-200"
        onClick={handleApiSubmit}
      >
        Update Trade URL List
      </button>
    </div>
  );
};
