import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTokenStatusRequest } from "../../features/orderSlice";
// ----------------------------------------------------------------------

export default function TokenInfo() {
  const dispatch = useDispatch();
  const statuses = useSelector((state) => state.orders.tokens);

  useEffect(() => {
    dispatch(getAllTokenStatusRequest());
  }, [dispatch]);
  return (
    <div>
      <h1 className="px-2 mb-3 font-semibold text-gray-800">
        Token Expiry Information
      </h1>
      {statuses &&
        statuses.length > 0 &&
        statuses.map((one, index) => (
          <div
            key={index}
            className="px-4 py-2 mb-4 text-sm text-red-800 rounded-lg w-fit bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span className="font-medium">{one}</span>
          </div>
        ))}
    </div>
  );
}
