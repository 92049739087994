import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fundAPI } from "../api/fundAPI";

// async thunk request to get All user
export const getAllFundsRequest = createAsyncThunk(
  "funds/getAllFundsRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await fundAPI.all();
      dispatch(setAllFunds(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleUserFundRequest = createAsyncThunk(
  "funds/getSingleUserFundRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await fundAPI.single(dt);
      dispatch(setSingleFunds(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allFunds: [],
  singleFunds: {},
  errorInFunds: "",
};

export const fundSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    setAllFunds: (state, action) => {
      state.allFunds = action.payload;
    },
    setSingleFunds: (state, action) => {
      state.singleCategory = action.payload;
    },
    clearHasError: (state) => {
      state.errorInFunds = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllFundsRequest.rejected, (state, action) => {
      state.errorInFunds = action.payload;
    });
    builder.addCase(getSingleUserFundRequest.rejected, (state, action) => {
      state.errorInFunds = action.payload;
    });
  },
});
export const { setAllFunds, setSingleFunds, clearHasError } = fundSlice.actions;

export default fundSlice.reducer;
