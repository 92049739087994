import React, { useEffect } from "react";
import { getAllUserPositionsRequest } from "../../features/positionSlice";
import { useSelector, useDispatch } from "react-redux";
import Searchable from "../../components/table/SearchableTable";

const headers = [
  { property: "name", name: "Client Name" },
  { property: "pl", name: "Profit/Loss" },
  { property: "symbol", name: "Symbol" },
  { property: "type", name: "Type" },
  { property: "buyAvg", name: "Buy Avg" },
  { property: "buyQty", name: "Buy Qty" },
  { property: "buyVal", name: "Buy Total" },
  { property: "sellAvg", name: "Sell Avg" },
  { property: "sellQty", name: "Sell Qty" },
  { property: "sellVal", name: "Sell Total" },
];

const AllPositions = () => {
  const dispatch = useDispatch();
  const allPositions = useSelector((state) => state.positions.allPositions);

  useEffect(() => {
    dispatch(getAllUserPositionsRequest());
  }, [dispatch]);
  return (
    <div>
      <h1 className="px-4 py-2 mb-2 font-semibold text-gray-800">
        All Positions ({allPositions?.length})
      </h1>
      <Searchable
        tableHeaders={headers}
        tableData={allPositions}
        defaultTableRow={2}
        searchBy="symbol"
        showSearch
        tableWidth="max-w-7xl"
      />
    </div>
  );
};

export default AllPositions;
