import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const clientAPI = {
  registerNew: async (info) =>
    axios.post(`${baseURL}/register-new`, info).then((res) => res.data),
  allActive: async () =>
    axios.get(`${baseURL}/all-active-clients`).then((res) => res.data),
  allSymbols: async () =>
    axios.get(`${baseURL}/all-symbols`).then((res) => res.data),
  holdingSymbols: async () =>
    axios.get(`${baseURL}/holding-symbols`).then((res) => res.data),
  allInActive: async () =>
    axios.get(`${baseURL}/all-inactive-clients`).then((res) => res.data),
  all: async () => axios.get(`${baseURL}/all-clients`).then((res) => res.data),
  singleClient: async (idToSearch) =>
    axios.get(`${baseURL}/single-client/${idToSearch}`).then((res) => res.data),
  updateClient: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios
      .put(`${baseURL}/update-client/${id}`, details)
      .then((res) => res.data);
  },
  totp: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios
      .put(`${baseURL}/user-totp-login/${id}`, details)
      .then((res) => res.data);
  },
  deleteClient: async (idToSearch) =>
    axios
      .delete(`${baseURL}/single-client/${idToSearch}`)
      .then((res) => res.data),
};
