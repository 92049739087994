import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { buysellAPI } from "../api/buysellAPI";
import { setMessage } from "./appSlice";

// async thunk request to Buy new Stock
export const buyNewStockRequest = // eslint-disable-next-line
  createAsyncThunk(
    "buysell/buyNewStockRequest",
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        const reg = await buysellAPI.buy(dataToSend);
        dispatch(setMessage(reg.message));
      } catch (error) {
        if (error.response) {
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

// async thunk request to Buy new Stock
export const getLatestPriceRequest = // eslint-disable-next-line
  createAsyncThunk(
    "buysell/getLatestPriceRequest",
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading(true));
        const reg = await buysellAPI.getPrice(dataToSend);
        dispatch(setPrice(reg.price));
        dispatch(setLoading(false));
      } catch (error) {
        if (error.response) {
          dispatch(setLoading(false));
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

// async thunk request to sell Holding
export const sellHoldingRequest = // eslint-disable-next-line
  createAsyncThunk(
    "buysell/sellHoldingRequest",
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        const reg = await buysellAPI.sell(dataToSend);
        dispatch(setMessage(reg.message));
      } catch (error) {
        if (error.response) {
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

// async thunk request to sell positions
export const sellPositionsRequest = // eslint-disable-next-line
  createAsyncThunk(
    "buysell/sellPositionsRequest",
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        const reg = await buysellAPI.positions(dataToSend);
        dispatch(setMessage(reg.message));
      } catch (error) {
        if (error.response) {
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

// async thunk request to sell Holding
export const panicSellAllPositionsRequest = // eslint-disable-next-line
  createAsyncThunk(
    "buysell/panicSellAllPositionsRequest",
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        const reg = await buysellAPI.panic(dataToSend);
        dispatch(setMessage(reg.message));
      } catch (error) {
        if (error.response) {
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

const initialState = {
  loading: false,
  errorInBuySell: "",
  price: 0,
};

export const buySellSlice = createSlice({
  name: "buysell",
  initialState,
  reducers: {
    clearHasError: (state) => {
      state.errorInBuySell = "";
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(buyNewStockRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(sellHoldingRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(panicSellAllPositionsRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(getLatestPriceRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
    builder.addCase(sellPositionsRequest.rejected, (state, action) => {
      state.errorInClient = action.payload;
    });
  },
});
export const { clearHasError, setPrice, setLoading } = buySellSlice.actions;

export default buySellSlice.reducer;
