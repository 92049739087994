import React, { useEffect } from "react";
import { getAllUserOrderRequest } from "../../features/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import Searchable from "../../components/table/SearchableTable";

const headers = [
  { property: "name", name: "Client Name" },
  { property: "description", name: "Stock" },
  { property: "side", name: "Type" },
  { property: "qty", name: "Quantity" },
  { property: "total", name: "Total" },
  { property: "clientId", name: "Fyers ID" },
  { property: "orderDateTime", name: "Date Time" },
];

const AllOrders = () => {
  const dispatch = useDispatch();
  const allOrders = useSelector((state) => state.orders.allOrders);

  useEffect(() => {
    dispatch(getAllUserOrderRequest());
  }, [dispatch]);
  return (
    <div>
      <h1 className="px-4 py-2 mb-2 font-semibold text-gray-800">
        All Orders ({allOrders?.length})
      </h1>
      <Searchable
        tableHeaders={headers}
        tableData={allOrders}
        defaultTableRow={2}
        searchBy="name"
        showSearch
        tableWidth="max-w-7xl"
      />
    </div>
  );
};

export default AllOrders;
