import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Clock from "../../Clock";
import { useSelector } from "react-redux";
import { FaPowerOff } from "react-icons/fa6";
import Dropdown from "../../Dropdown";

const Header = () => {
  const user = useSelector((state) => state.admin.userDetails);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem("alpadmToken");
    navigate("/");
  };

  return (
    <div className="flex items-center justify-between flex-1 w-full p-6 space-x-2 xs:pl-0 md:pr-6 z-5">
      <div className="w-full">
        <div className="relative flex-1 rounded">
          <div className="absolute inset-y-0 left-0 flex items-center pl-4 text-zinc-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="h-4 w-4 flex-shrink-0 stroke-[2.25] "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
          <input
            placeholder="Search"
            className="block h-10 py-1 border-none rounded-lg hover:cursor-pointer bg-zinc-100 text-zinc-900 placeholder:text-zinc-500 pl-11 focus:border-zinc-300 focus:outline-none focus:ring-transparent focus:placeholder-transparent hover:opacity-50 focus:opacity-100"
            id="ui-navbar-search-input"
            role="combobox"
            type="text"
          />
        </div>
      </div>
      <div className="flex-1">
        <div>
          <Link to="/dashboard/clients/add-new-user">
            <button className="flex items-center h-10 px-4 text-sm font-medium transition-all ease-in-out rounded-lg focus:outline-none focus:ring-0 focus:ring-transparent whitespace-nowrap duration-50 bg-zinc-100 border-cool-gray-200 text-zinc-900 hover:bg-zinc-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                className=" flex-shrink-0 stroke-[2.25] h-4 w-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add Client
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
