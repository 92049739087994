import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const buysellAPI = {
  buy: async (info) =>
    axios.post(`${baseURL}/buy-now`, info).then((res) => res.data),
  sell: async (info) =>
    axios.post(`${baseURL}/sell-holdings-now`, info).then((res) => res.data),
  positions: async (info) =>
    axios.post(`${baseURL}/sell-positions-now`, info).then((res) => res.data),
  getPrice: async (info) =>
    axios.post(`${baseURL}/get-price-ltp`, info).then((res) => res.data),
  panic: async () =>
    axios.get(`${baseURL}/panic-sell-all-positions`).then((res) => res.data),
};
