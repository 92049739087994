import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { positionAPI } from "../api/positionAPI";

// async thunk request to get All user
export const getAllUserPositionsRequest = createAsyncThunk(
  "positions/getAllUserPositionsRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await positionAPI.all();
      dispatch(setAllPositions(data.positions));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSinglePositionRequest = createAsyncThunk(
  "positions/getSinglePositionRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await positionAPI.single(dt);
      dispatch(setSingleUserPositions(data.positions));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allPositions: [],
  singleUserPositions: [],
  errorInPosition: "",
};

export const positionSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    setAllPositions: (state, action) => {
      state.allPositions = action.payload;
    },
    setSingleUserPositions: (state, action) => {
      state.singleCategory = action.payload;
    },
    clearHasError: (state) => {
      state.errorInPosition = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllUserPositionsRequest.rejected, (state, action) => {
      state.errorInPosition = action.payload;
    });
    builder.addCase(getSinglePositionRequest.rejected, (state, action) => {
      state.errorInPosition = action.payload;
    });
  },
});
export const { setAllPositions, setSingleUserPositions, clearHasError } =
  positionSlice.actions;

export default positionSlice.reducer;
