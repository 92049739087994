import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik, FormikProvider, Field, Form, ErrorMessage } from "formik";
import SuccessModal from "../../components/modals/SuccessModal";

import {
  addNewClientRequest,
  updateClientRequest,
  setSingleClient,
  clearHasError,
} from "../../features/clientSlice";
import { clientAPI } from "../../api/clientAPI";
import { setMessage } from "../../features/appSlice";

const clientValidationSchema = yup.object({
  name: yup.string().required("Please enter Client Name"),
  userId: yup.string().required("Please choose username for Alphafolio"),
  password: yup.string().required("Please choose password for Alphafolio"),
  apiKey: yup.string().required("Please Enter Fyers API ID"),
  apiSecret: yup.string().required("Please Enter Fyers Secret ID"),
  redirectUri: yup.string().required("Please Enter Fyers APP Redirect URI"),
  pin: yup.string().required("Please Enter Fyers LOGIN PIN"),
  mobile: yup.string().required("Please Enter Fyers Mobile Number"),
  clientId: yup.string().required("Please Enter Fyers Login User ID"),
});

const updateClientValidationSchema = yup.object({
  ...clientValidationSchema.fields,
  active: yup.boolean(),
});

const AddUpdateClient = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const existingClient = useSelector((state) => state.clients.singleClient);
  const serverMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.clients.errorInClient);

  const formik = new useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (isEdit && existingClient?.name) || "",
      userId: (isEdit && existingClient?.userId) || "",
      password: (isEdit && existingClient?.password) || "",
      apiKey: (isEdit && existingClient?.apiKey) || "",
      apiSecret: (isEdit && existingClient?.apiSecret) || "",
      redirectUri: (isEdit && existingClient?.redirectUri) || "",
      pin: (isEdit && existingClient?.pin) || "",
      mobile: (isEdit && existingClient?.mobile) || "",
      clientId: (isEdit && existingClient?.clientId) || "",
    },
    validationSchema: isEdit
      ? updateClientValidationSchema
      : clientValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        setSubmitting(true);
        if (isEdit) {
          const sendData = {
            ...values,
            active,
            id,
          };
          dispatch(updateClientRequest(sendData));
        }
        if (!isEdit) {
          const sendData = {
            ...values,
          };
          dispatch(addNewClientRequest(sendData));
        }
        setSubmitting(false);
        resetForm();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      async function getSingle() {
        const allServerValues = await clientAPI.singleClient(id);
        dispatch(setSingleClient(allServerValues));
        setActive(allServerValues.active);
      }
      getSingle();
    }
  }, [dispatch, isEdit, id]);

  const successAndRedirect = () => {
    dispatch(setMessage(""));
    navigate(`/dashboard/clients/all-clients`);
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  return (
    <>
      {serverMessage && (
        <SuccessModal
          open={Boolean(serverMessage)}
          text={serverMessage}
          deleteText="OK"
          clickFunction={successAndRedirect}
        />
      )}
      {errorMessage && (
        <SuccessModal
          open={Boolean(errorMessage)}
          text={errorMessage}
          deleteText="OK"
          clickFunction={errorAndStay}
          error
        />
      )}

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 gap-4 pr-4 md:grid-cols-3">
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Client Name
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter Client Name"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-sm text-red-500"
              />
            </div>

            {!isEdit && (
              <div className="mb-4">
                <label
                  htmlFor="userId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Choose User ID (Alphafolio)
                </label>
                <Field
                  type="text"
                  id="userId"
                  name="userId"
                  className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                  placeholder="Enter User ID"
                />
                <ErrorMessage
                  name="userId"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
            )}

            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password (Alphafolio)
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter Password"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
          </div>
          <h1 className="py-2 mb-2 font-semibold text-gray-800">
            Enter Fyers Credential Below
          </h1>
          <div className="grid grid-cols-1 gap-4 pr-4 md:grid-cols-4">
            <div className="mb-4">
              <label
                htmlFor="clientId"
                className="block text-sm font-medium text-gray-700"
              >
                Fyers Login ID
              </label>
              <Field
                type="text"
                id="clientId"
                name="clientId"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter API Key"
              />
              <ErrorMessage
                name="clientId"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="apiKey"
                className="block text-sm font-medium text-gray-700"
              >
                Fyers API ID
              </label>
              <Field
                type="text"
                id="apiKey"
                name="apiKey"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter API Key"
              />
              <ErrorMessage
                name="apiKey"
                component="div"
                className="text-sm text-red-500"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="apiSecret"
                className="block text-sm font-medium text-gray-700"
              >
                Fyers Secret ID
              </label>
              <Field
                type="text"
                id="apiSecret"
                name="apiSecret"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter API Secret"
              />
              <ErrorMessage
                name="apiSecret"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="pin"
                className="block text-sm font-medium text-gray-700"
              >
                Fyers PIN
              </label>
              <Field
                type="text"
                id="pin"
                name="pin"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter API Secret"
              />
              <ErrorMessage
                name="pin"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="redirectUri"
                className="block text-sm font-medium text-gray-700"
              >
                Redirect URI
              </label>
              <Field
                type="text"
                id="redirectUri"
                name="redirectUri"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter Redirect URI"
              />
              <ErrorMessage
                name="redirectUri"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number (Registered In Fyers)
              </label>
              <Field
                type="text"
                id="mobile"
                name="mobile"
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
                placeholder="Enter Mobile Number"
              />
              <ErrorMessage
                name="mobile"
                component="div"
                className="text-sm text-red-500"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="active"
                className="block text-sm font-medium text-center text-gray-700"
              >
                Active
              </label>
              <input
                type="checkbox"
                id="active"
                name="active"
                checked={active}
                onChange={(event) => setActive(event.target.checked)}
                className="w-full p-2 px-4 mt-1 border rounded-md outline-none focus:border-blue-400"
              />
            </div>
          </div>

          <div className="mt-4">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="px-4 py-2 text-base font-semibold text-center rounded-lg w-w-full hover:bg-theme-yellow/80 disabled:bg-gray-400 bg-theme-yellow"
            >
              {isEdit ? "Update Client" : "Add New Client"}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AddUpdateClient;
