import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const holdingAPI = {
  all: async () =>
    axios.get(`${baseURL}/all-user-holdings`).then((res) => res.data),
  single: async (idToSearch) =>
    axios.get(`${baseURL}/user-holdings/${idToSearch}`).then((res) => res.data),
};
