import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const positionAPI = {
  all: async () =>
    axios.get(`${baseURL}/all-user-positions`).then((res) => res.data),
  single: async (idToSearch) =>
    axios
      .get(`${baseURL}/user-positions/${idToSearch}`)
      .then((res) => res.data),
};
