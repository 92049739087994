import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BuySellWidget from "../components/layouts/dashboardcomp/BuySellWidget";
import WatchList from "../components/layouts/dashboardcomp/WatchList";
import Statistics from "../components/layouts/dashboardcomp/Statistics";
import TokenStatus from "../components/customers/TokenStatus";
import TokenInfo from "../components/customers/TokenExpiryInfo";
import { getNewStocksWatchListRequest } from "../features/watchListSlice";
import notificationSoundPath from "../notification.wav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastNotification from "../components/ToastNotification";

const MainDashboard = () => {
  const user = useSelector((state) => state.admin.adminDetails);
  const newWatchList = useSelector((state) => state.watchList.newStocks);

  console.log(newWatchList, "new watch at main dashboard");
  useEffect(() => {}, [newWatchList?.length]);
  return (
    <>
      {user?.name && (
        <h1 className="px-4 py-2 mb-2 font-semibold text-gray-800">
          Welcome {user.name}!
        </h1>
      )}
      <ToastNotification />
      <div className="flex justify-between space-x-4">
        <div className="w-2/3">
          <Statistics />
          <TokenStatus />
          <TokenInfo />
        </div>
        <div className="flex flex-col w-full space-y-6 lg:w-[40%] lg:pl-6">
          <WatchList />
          <BuySellWidget />
        </div>
      </div>
    </>
  );
};

export default MainDashboard;
