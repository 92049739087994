import React from "react";

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  console.log(totalPages, "totalPages", currentPage);
  return (
    <div className="flex flex-row justify-end space-x-3">
      <button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((prev) => prev - 1)}
        className={`flex items-center justify-center space-x-2 rounded-lg p-2.5 text-center text-xs font-medium focus:outline-none ${
          currentPage === 1
            ? "cursor-not-allowed hover:text-gray-300 opacity-50"
            : "cursor-pointer hover:text-gray-500"
        }  text-gray-900 `}
      >
        Previous
      </button>
      <button className="flex items-center justify-center space-x-2 rounded-lg px-1.5 py-0.5 text-center text-sm font-medium text-gray-900 focus:outline-none bg-white border border-gray-200 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-100 w-8">
        {currentPage}
      </button>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((prev) => prev + 1)}
        className={`flex items-center justify-center space-x-2 rounded-lg p-2.5 text-center text-xs font-medium focus:outline-none ${
          currentPage === totalPages
            ? "cursor-not-allowed hover:text-gray-300 opacity-50"
            : "cursor-pointer hover:text-gray-500"
        }  text-gray-900 `}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
