import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { watchListAPI } from "../api/watchListAPI";

// async thunk request to get updated last 3 min watchlist
export const getLatestWatchListRequest = createAsyncThunk(
  "watchlist/getLatestWatchListRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await watchListAPI.all();
      dispatch(setAllList(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get New Stocks
export const getNewStocksWatchListRequest = createAsyncThunk(
  "watchlist/getNewStocksWatchListRequest",
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await watchListAPI.allNew();
      dispatch(setNewStocks(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  all: [],
  newStocks: [],
  errorInWatchList: "",
};

export const watchListSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    setAllList: (state, action) => {
      state.all = action.payload;
    },
    setNewStocks: (state, action) => {
      state.newStocks = action.payload;
    },
    clearHasError: (state) => {
      state.errorInWatchList = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getLatestWatchListRequest.rejected, (state, action) => {
      state.errorInWatchList = action.payload;
    });
    builder.addCase(getNewStocksWatchListRequest.rejected, (state, action) => {
      state.errorInWatchList = action.payload;
    });
  },
});
export const { setAllList, clearHasError, setNewStocks } =
  watchListSlice.actions;

export default watchListSlice.reducer;
