import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import DashboardLayout from "./components/layouts/dashboard";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import MainDashboard from "./pages/MainDashboard";
import ChangePassword from "./pages/ChangePassword";
import Clients from "./pages/clients/Clients";
import TotpForm from "./pages/clients/TotpForm";
import AllPositions from "./pages/positions/AllPositions";
import AllHoldings from "./pages/holdings/AllHoldings";
import AllOrders from "./pages/orders/AllOrders";
import AddUpdateClient from "./pages/clients/AddUpdateClient";
import Configuration from "./pages/Configuration";

// Define the routes
const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "dashboard/*",
    element: <DashboardLayout />,
    children: [
      { path: "", element: <Navigate to="/dashboard/main" replace /> },
      {
        path: "main",
        element: <MainDashboard />,
      },
      {
        path: "clients",

        children: [
          { path: "", element: <Navigate to="/clients/all-clients" replace /> },
          { path: "all-clients", element: <Clients /> },
          { path: "add-new-user", element: <AddUpdateClient /> },
          { path: "all-clients/:id", element: <AddUpdateClient isEdit /> },
          { path: "all-clients/totp/:id", element: <TotpForm /> },
          // { path: 'all-clients/delete/:id', element: <DeleteUser /> }
        ],
      },
      {
        path: "positions",

        children: [
          {
            path: "",
            element: <Navigate to="/positions/all-positions" replace />,
          },
          { path: "all-positions", element: <AllPositions /> },
        ],
      },
      {
        path: "holdings",

        children: [
          {
            path: "",
            element: <Navigate to="/holdings/all-holdings" replace />,
          },
          { path: "all-holdings", element: <AllHoldings /> },
        ],
      },
      {
        path: "orders",

        children: [
          {
            path: "",
            element: <Navigate to="/orders/all-orders" replace />,
          },
          { path: "all-orders", element: <AllOrders /> },
        ],
      },
      {
        path: "settings/change-password",
        element: <ChangePassword />,
      },
      {
        path: "configuration",
        element: <Configuration />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

// Define the AppRouter component
export default function AppRouter() {
  return useRoutes(routes);
}
