import React, { useEffect, useState } from "react";
import SymbolDropdown from "../../SymbolDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getLatestWatchListRequest } from "../../../features/watchListSlice";
import {
  getLatestPriceRequest,
  clearHasError,
  buyNewStockRequest,
} from "../../../features/buySellSlice";
import { setMessage } from "../../../features/appSlice";
import WarningModal from "../../../components/modals/WarningModal";
import SuccessModal from "../../../components/modals/SuccessModal";

const BuySellWidget = () => {
  const dispatch = useDispatch();
  const watchList = useSelector((state) => state.watchList.all);
  const fetching = useSelector((state) => state.buysell.loading);
  const newPrice = useSelector((state) => state.buysell.price);
  const successMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.buysell.errorInBuySell);
  const [tab, setTab] = useState("BUY");
  const [reviewModal, setReviewModal] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [amount, setAmount] = useState(0);
  const buySymbols =
    watchList && watchList.length > 0 ? watchList.map((one) => one.symbol) : [];

  const checkDisabled = () => {
    if (
      !symbol ||
      newPrice === -1 ||
      (tab === "BUY" && !amount) ||
      tab == "SELL"
    ) {
      return true;
    }
    return false;
  };

  const closeModal = () => {
    setReviewModal(false);
  };

  const getNewSymbolPrice = (sym) => {
    if (sym) {
      dispatch(getLatestPriceRequest({ symbol: sym }));
    }
  };

  const successAndRedirect = () => {
    dispatch(setMessage(""));
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  const buyNowStock = () => {
    if (symbol && amount) {
      dispatch(buyNewStockRequest({ symbol, amount }));
    }
    setReviewModal(false);
    setAmount(0);
    setSymbol("");
  };
  // Fetch watchlist immediately when the component mounts
  useEffect(() => {
    dispatch(getLatestWatchListRequest());
  }, [dispatch]);

  useEffect(() => {}, [symbol]);

  return (
    <div className="px-6 pb-4 mr-2 space-y-3 bg-white border border-gray-200 rounded-lg">
      <WarningModal
        open={reviewModal}
        closeModal={closeModal}
        text={`Are you sure you want to ${tab} ${symbol}`}
        buttonText={`Proceed to ${tab}`}
        buttonFunction={buyNowStock}
      />

      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />

      <div style={{ border: "none", padding: "0px" }}>
        <div className="cursor-pointer">
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
            <ul className="flex flex-wrap -mb-px">
              <li
                onClick={() => {
                  setTab("BUY");
                  setSymbol("");
                }}
                className="me-2"
              >
                <span
                  className={`inline-block p-4 border-b-2 ${
                    tab === "BUY"
                      ? "text-green-600  border-green-600"
                      : "hover:border-gray-300"
                  } rounded-t-lg`}
                >
                  Buy
                </span>
              </li>
              <li
                onClick={() => {
                  setTab("SELL");
                  setSymbol("");
                }}
                className="me-2"
              >
                <span
                  className={`inline-block p-4 border-b-2 ${
                    tab === "SELL"
                      ? "text-red-600  border-red-600"
                      : "hover:border-gray-300"
                  } rounded-t-lg`}
                  aria-current="page"
                >
                  Sell
                </span>
              </li>
            </ul>
          </div>
          <label
            htmlFor="symbol"
            className="block mt-4 text-sm font-medium text-gray-900"
          >
            Symbol
          </label>
          <SymbolDropdown
            symbol={symbol}
            setSymbol={setSymbol}
            items={tab === "BUY" ? buySymbols : []}
            getNewSymbolPrice={getNewSymbolPrice}
          />
          {!fetching && symbol && newPrice > 0 && (
            <p className="text-xs font-medium text-green-600 ">{`LIVE ${symbol} = ₹${newPrice}`}</p>
          )}
          {fetching && (
            <p className="text-xs font-medium">{`Fetching price ... `}</p>
          )}
          {!fetching && symbol && newPrice === -1 && (
            <p className="text-xs font-medium text-red-500">
              Error While fetching price
            </p>
          )}

          {tab === "BUY" && (
            <div>
              <label
                htmlFor="amount"
                className="block my-2 text-sm font-medium text-gray-900"
              >
                Amount (₹)
              </label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="bg-gray-50 border border-gray-300 outline-none text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                placeholder="Enter Amount"
                required
              />
            </div>
          )}
          {tab === "SELL" && (
            <div>
              <label
                htmlFor="amount"
                className="block my-2 text-sm font-medium text-gray-900"
              >
                Quantity
              </label>
              <input
                type="number"
                id="quantity"
                className="bg-gray-50 border border-gray-300 outline-none text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                placeholder="Enter Quantity"
                required
              />
            </div>
          )}
          <div className="mt-4">
            <button
              onClick={() => setReviewModal(true)}
              disabled={checkDisabled()}
              className="w-full px-4 py-2 text-base font-semibold text-center rounded-lg disabled:bg-gray-300 hover:bg-theme-yellow/80 bg-theme-yellow"
            >
              {tab} Review Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySellWidget;
