import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FcExpired } from "react-icons/fc";
import { GrValidate } from "react-icons/gr";
import { getActiveClientRequest } from "../../features/clientSlice";
import { isSameDayAndTimeWithin16Hours } from "../../helpers";

const TokenStatus = () => {
  const dispatch = useDispatch();
  const today = new Date().toISOString();
  const clients = useSelector((state) => state.clients.active);

  useEffect(() => {
    dispatch(getActiveClientRequest());
  }, [dispatch]);
  return (
    <div className="mb-2">
      <h1 className="px-2 font-semibold text-gray-800">
        Auto Token Renew Status
      </h1>
      {clients &&
        clients.length > 0 &&
        clients.map((one, index) => {
          return (
            <div
              key={index}
              className="justify-center inline-block px-4 py-1 space-x-3 text-base text-black bg-white border rounded-lg active md:my-2"
            >
              <span>{one.name}</span>
              {isSameDayAndTimeWithin16Hours(one.issuedAt, today) ? (
                <GrValidate className="inline text-base text-green-600" />
              ) : (
                <FcExpired className="inline text-xl" />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default TokenStatus;
