import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLatestWatchListRequest } from "../../../features/watchListSlice";
import { formatDateTime } from "../../../helpers";
import Pagination from "../../Pagination";

function getStringBetweenDashes(inputString) {
  const match = inputString.match(/:(.*?)-/);

  if (match && match.length === 2) {
    return match[1];
  } else {
    return null;
  }
}

const WatchList = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const watchList = useSelector((state) => state.watchList.all);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentResultsToMap =
    watchList &&
    watchList.length > 0 &&
    watchList.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const searchedStocks =
    searchValue &&
    watchList &&
    watchList.length > 0 &&
    watchList.filter((one) => {
      if (one.symbol?.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
      if (one.stockName?.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
      return false;
    });

  const fetchWatchList = () => {
    dispatch(getLatestWatchListRequest());
  };

  useEffect(() => {
    fetchWatchList();

    const intervalId = setInterval(() => {
      fetchWatchList();
    }, 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {}, [postsPerPage]);

  return (
    <div className="p-2 py-4 mr-2 bg-white border border-gray-200 rounded-lg">
      <div className="flex items-start justify-between space-x-2">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            type="text"
            id="voice-search"
            className="bg-gray-50 border outline-none focus:border-theme-yellow rounded-lg border-gray-300 text-gray-900 md:text-sm text-xs focus:ring-theme-blue focus:border-theme-blue block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-theme-blue dark:focus:border-theme-blue"
            placeholder="Search Stocks..."
            required
          />
        </div>
      </div>

      <div className="p-2">
        {!searchValue &&
          currentResultsToMap &&
          currentResultsToMap.length > 0 &&
          currentResultsToMap.map((one, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between py-1 "
              >
                <p
                  className="items-center w-full text-sm font-medium transition cursor-pointer whitespace-nowrap hover:text-red-600 focus:outline-none focus:ring-0 focus:ring-transparent"
                  title={one.stockName}
                >
                  {getStringBetweenDashes(one.symbol)}
                </p>
                <div className="flex items-center justify-between w-full ">
                  <p className="pl-1 text-sm text-emerald-500">
                    {one.changesInPercentage}
                  </p>
                  <p className="pl-1 text-sm ">₹ {one.price}</p>
                </div>
              </div>
            );
          })}
        {searchValue &&
          searchedStocks &&
          searchedStocks.length > 0 &&
          searchedStocks.map((one, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between py-1 "
              >
                <p
                  className="items-center w-full text-sm font-medium transition cursor-pointer whitespace-nowrap hover:text-red-600 focus:outline-none focus:ring-0 focus:ring-transparent"
                  title={one.stockName}
                >
                  {getStringBetweenDashes(one.symbol)}
                </p>
                <div className="flex items-center justify-between w-full ">
                  <p className="pl-1 text-sm text-emerald-500">
                    {one.changesInPercentage}
                  </p>
                  <p className="pl-1 text-sm ">₹ {one.price}</p>
                </div>
              </div>
            );
          })}

        {(!searchValue && watchList && watchList.length === 0) ||
          (searchValue && searchedStocks && searchedStocks.length === 0 && (
            <p className="flex items-end justify-center">No Stocks</p>
          ))}
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(watchList?.length / postsPerPage)}
      />
    </div>
  );
};

export default WatchList;
