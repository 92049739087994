// Dashboard.js
import React, { useEffect } from "react";
import { useNavigate, Outlet, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";
import { setAuthorizationToken } from "../../../helpers";
import { setIsAuthenticated } from "../../../features/appSlice";
import { getUserRequest } from "../../../features/adminSlice";
import Header from "./Header";
import SideBar from "./Sidebar";

const sidebarItems = [
  {
    name: "Home",
    link: "/dashboard/main",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
        ></path>
      </svg>
    ),
    subItems: [],
  },
  {
    name: "Clients",
    link: "/dashboard/clients/all-clients",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
        ></path>
      </svg>
    ),
    subItems: [],
  },
  {
    name: "Positions",
    link: "/dashboard/positions/all-positions",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
        ></path>
      </svg>
    ),
    subItems: [],
  },
  {
    name: "Orders",
    link: "/dashboard/orders/all-orders",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
        ></path>
      </svg>
    ),
    subItems: [],
  },
  {
    name: "Holdings",
    link: "/dashboard/holdings/all-holdings",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
        ></path>
      </svg>
    ),
    subItems: [],
  },
  {
    name: "Configuration",
    link: "/dashboard/configuration",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
        ></path>
      </svg>
    ),
    subItems: [],
  },
];
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const token = localStorage.getItem("alpadmToken");
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);

  useEffect(() => {
    if (!token) {
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));
      navigate("/");
      return;
    }
    if (token) {
      const { expiresIn } = decode(JSON.parse(token));
      if (expiresIn * 1000 > Date.now()) {
        dispatch(setIsAuthenticated(true));
        setAuthorizationToken(JSON.parse(token));
        dispatch(getUserRequest());
        return;
      } else {
        localStorage.removeItem("alpadmToken");
        dispatch(setIsAuthenticated(false));
        setAuthorizationToken(false);
        navigate("/");
      }
    }
    // eslint-disable-next-line
  }, [token, dispatch]);
  return (
    <div className="flex items-">
      <SideBar sidebarItems={sidebarItems} />
      <div className="self-start w-full">
        <Header />
        {isAuthenticated ? (
          <Outlet />
        ) : (
          <Navigate
            to={{ from: location.state?.from }}
            state={{ from: location.state?.from }}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
