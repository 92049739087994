import axios from "axios";

export const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Access-control-allow-credentials"] = true;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const isWeekendInIndia = () => {
  const currentDate = new Date();
  const currentOffset = currentDate.getTimezoneOffset();
  const ISTOffset = 330; // IST offset UTC +5:30
  const ISTTime = new Date(
    currentDate.getTime() + (ISTOffset + currentOffset) * 60000
  );
  const indiaNow = new Date(ISTTime);
  const monday = indiaNow.getDay() === 1;
  const hours = indiaNow.getHours();
  const minutes1 = indiaNow.getMinutes();
  const HH = hours < 10 ? `0${hours}` : hours;
  const MM = minutes1 < 10 ? `0${minutes1}` : minutes1;
  const currentTimeNow1 = `${HH}:${MM}`;
  return (
    (indiaNow.getDay() === 6 && currentTimeNow1 > "04:30") ||
    indiaNow.getDay() === 0 ||
    (monday && currentTimeNow1 < "04:30")
  );
};

export function formatDateTime(isoDateTime) {
  // Set the time zone to Indian Standard Time (IST)
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata", // IST time zone
  };

  const formattedDate = new Intl.DateTimeFormat("en-IN", options).format(
    new Date(isoDateTime)
  );

  return {
    date: formattedDate,
    time: formattedDate.split(", ")[1],
  };
}

export function isSameDayAndTimeWithin16Hours(date1, date2) {
  const indiaTimezoneOffset = 5.5 * 60 * 60 * 1000; // Offset for India time (IST)

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Convert the dates to India time by adding the offset
  const indiaDate1 = new Date(d1.getTime() + indiaTimezoneOffset);
  const indiaDate2 = new Date(d2.getTime() + indiaTimezoneOffset);

  // Check if the dates have the same year, month, and day
  const isSameDay =
    indiaDate1.getUTCFullYear() === indiaDate2.getUTCFullYear() &&
    indiaDate1.getUTCMonth() === indiaDate2.getUTCMonth() &&
    indiaDate1.getUTCDate() === indiaDate2.getUTCDate();

  // Check if the time difference is within 10 hours
  const timeDifference = Math.abs(indiaDate1.getTime() - indiaDate2.getTime());
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  return isSameDay && hoursDifference <= 16;
}

export function checkExpiry(isoDate) {
  const currentDate = new Date();
  const expirationDate = new Date(isoDate);
  expirationDate.setDate(expirationDate.getDate() + 12);
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const differenceInDays = Math.floor(
    (expirationDate - currentDate) / millisecondsPerDay
  );

  if (differenceInDays < 0) {
    const daysAgo = Math.abs(differenceInDays);
    return `Token expired`;
  } else {
    return `${differenceInDays} day${differenceInDays === 1 ? "" : "s"}`;
  }
}

export function formatMoney(value) {
  // Check if the value is not a number
  if (isNaN(value)) {
    return "Invalid input";
  }

  // Convert the value to a number with 2 decimal places
  const numericValue = parseFloat(value);

  // Check if the conversion resulted in a valid number
  if (isNaN(numericValue)) {
    return "Invalid input";
  }

  // Format the numeric value to have 2 decimal places
  const formattedValue = numericValue.toFixed(2);

  // Split the integer and decimal parts
  const [integerPart, decimalPart] = formattedValue.split(".");

  // Add commas to the integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Join the integer and decimal parts
  const result = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return result;
}

export const sumProperty = (array, property) =>
  array.reduce((acc, obj) => {
    // Check if the object exists and the property is not undefined or null
    if (obj && obj[property] !== undefined && obj[property] !== null) {
      return acc + obj[property];
    }
    return acc;
  }, 0);

export function processNumber(input) {
  if (typeof input === "string") {
    // Check if it's a valid number
    const parsedFloat = parseFloat(input);

    if (!isNaN(parsedFloat)) {
      // Check if it's a whole number
      if (Number.isInteger(parsedFloat)) {
        return parsedFloat; // Return as it is
      } else {
        return parsedFloat.toFixed(2); // Return as a float with two decimal places
      }
    } else {
      return input; // Return null for invalid input
    }
  } else if (typeof input === "number") {
    // Check if it's a whole number
    if (Number.isInteger(input)) {
      return input; // Return as it is
    } else {
      return input.toFixed(2); // Return as a float with two decimal places
    }
  } else {
    return null; // Return null for other types
  }
}
