import React, { useEffect, useState } from "react";
import { getAllClientsRequest } from "../../features/clientSlice";
import { useSelector, useDispatch } from "react-redux";
import MoreOptions from "../../components/table/MoreOptions";
import { checkExpiry, processNumber } from "../../helpers";

const headers = [
  { property: "name", name: "Client Name" },
  { property: "freeMargin", name: "Free Margin" },
  { property: "pl", name: "Profit/Loss" },
  { property: "symbol", name: "Holdings" },
  { property: "symbol", name: "Status" },
  { property: "holdingType", name: "Phone" },
  { property: "remainingQuantity", name: "Token Validity" },
];

const Clients = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients.allClients);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);

  const handleOptionClick = (option, rowData) => {
    // Handle the option click logic here
    console.log(`Clicked "${option.label}" for row:`, rowData);
  };

  const filteredTableData =
    clients &&
    clients.length > 0 &&
    clients.filter((row) => {
      if (!searchTerm) return true;
      const searchString = row[name].toLowerCase();
      return searchString.includes(searchTerm.toLowerCase());
    });

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to the first page when changing page size
    setOpen(false); //
  };

  const totalRows = filteredTableData.length;
  const totalPages = Math.ceil(totalRows / pageSize);

  const paginatedData =
    filteredTableData &&
    filteredTableData.length > 0 &&
    filteredTableData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

  useEffect(() => {
    dispatch(getAllClientsRequest());
  }, [dispatch]);
  return (
    <div>
      <h1 className="px-4 py-2 mb-2 font-semibold text-gray-800">
        Clients ({clients?.length})
      </h1>
      <>
        {/* Search input */}

        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="flex-shrink-0 stroke-[2.25] h-[20px] w-[20px] absolute top-1/2 -translate-y-1/2 transform text-gray-500 left-3.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            ></path>
          </svg>
          <input
            className="block h-10 outline-none rounded-lg px-[14.5px] text-base pl-[42.5px] bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-500 focus:border-theme-yellow  w-96 pr-8"
            type="text"
            placeholder={`Search by Client's Name...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div
          className={`relative h-full  my-4 mr-2 border border-gray-300 overflow-auto text-left rounded-lg tableScrollbar`}
          style={{ maxHeight: "450px" }}
        >
          {/* Table */}
          <table
            className="w-auto text-base transition-opacity border-[1.25px]  rounded-md  border-gray-300 table-fixed"
            style={{ minWidth: "100%" }}
          >
            <thead className="sticky top-0 z-10 w-full bg-white">
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`relative h-11 cursor-pointer overflow-visible  bg-gray-50 p-0 font-medium text-gray-900 ${
                      index === 0 ? "text-left" : "text-center"
                    }`}
                    style={{ backgroundClip: "text" }}
                  >
                    <div
                      className={`flex h-11 items-center space-x-3 border-b-[1.25px] border-r-[1.25px]  bg-gray-50 p-0 px-4 justify-${
                        index === 0 ? "start " : "center "
                      }`}
                    >
                      <div>{header.name}</div>
                    </div>
                  </th>
                ))}

                <th className="relative p-0 font-medium text-center text-gray-900 h-11 bg-gray-50">
                  <div className="flex h-11 items-center space-x-3 border-b-[1.25px] border-r-[1.25px]  bg-gray-50 p-0 px-4 justify-center">
                    <div>Options</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((rowData, rowIndex) => {
                  const {
                    name,
                    active,
                    createdAt,
                    mobile,
                    updatedAt,
                    phrase,
                    refreshTokenIssueDate,
                  } = rowData;
                  return (
                    <tr key={rowIndex}>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11 items-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4 `}
                        >
                          <div title={phrase} className="text-sm text-gray-500">
                            {name}
                          </div>
                        </div>
                      </td>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11 items-center justify-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4 `}
                        >
                          <div className="text-sm text-gray-500">-</div>
                        </div>
                      </td>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11 items-center justify-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4 `}
                        >
                          <div className="text-sm text-gray-500">-</div>
                        </div>
                      </td>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11 items-center justify-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4 `}
                        >
                          <div className="text-sm text-gray-500">-</div>
                        </div>
                      </td>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11 items-center justify-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4  `}
                        >
                          <div
                            className={`text-sm ${
                              active ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {active ? "Active" : "Inactive"}
                          </div>
                        </div>
                      </td>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11  items-center justify-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4 `}
                        >
                          <div className="text-sm text-gray-500">{mobile}</div>
                        </div>
                      </td>
                      <td
                        className={`relative h-11 cursor-pointer overflow-visible bg-white p-0 font-medium text-gray-900 
                       `}
                      >
                        <div
                          className={`flex h-11 items-center justify-center space-x-3 border-b-[1.25px] border-r-[1.25px] border-gray-300 p-0 px-4 `}
                        >
                          <div className="text-sm text-gray-500">
                            {checkExpiry(refreshTokenIssueDate)}
                          </div>
                        </div>
                      </td>
                      <MoreOptions
                        editText="Edit Client"
                        editLink={`/dashboard/clients/all-clients/${rowData._id}`}
                        totpText="Enter TOTP"
                        totpLink={`/dashboard/clients/all-clients/totp/${rowData._id}`}
                        onOptionClick={(option) =>
                          handleOptionClick(option, rowData)
                        }
                      />
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {paginatedData?.length === 0 && (
            <div className="flex h-11 items-center justify-center px-2.5 text-gray-500">
              No matching records found
            </div>
          )}
          {/* Pagination */}

          <div className="z-40 flex flex-row items-center justify-between mt-4">
            <div className="flex items-center text-sm font-normal">
              <div className="relative">
                <button
                  onClick={() => setOpen(!open)}
                  className="relative flex items-center whitespace-nowrap font-medium ease-in-out focus:ring-0 focus:ring-transparent bg-white border-[1.5px] border-gray-400 hover:bg-gray-200 hover:border-gray-200 transition duration-200  focus:border-gray-500 focus:outline-none text-base px-4 h-8 rounded-md ml-1"
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  data-headlessui-state=""
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-wrap pr-1">
                      <span className="text-sm font-normal text-gray-700">
                        {pageSize}
                      </span>
                    </div>
                    <div className="flex pl-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="flex-shrink-0 stroke-[2.25] -my-2 h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </button>
                {/* Dropdown for page size */}
                {open && (
                  <div className="absolute z-40 w-24 mt-1 overflow-hidden bg-white border border-gray-300 rounded-md shadow-lg">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="headlessui-listbox-label-:r3: headlessui-listbox-button-:r4:"
                    >
                      {[25, 50, 100, 250, 500, 1000].map((option) => (
                        <div
                          key={option}
                          onClick={() => handlePageSizeChange(option)}
                          className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                          role="menuitem"
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-end space-x-3">
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => prev - 1)}
                className={`flex items-center justify-center space-x-2 rounded-lg p-2.5 text-center text-sm font-medium focus:outline-none ${
                  currentPage === 1
                    ? "cursor-not-allowed hover:text-gray-300 opacity-50"
                    : "cursor-pointer hover:text-gray-500"
                }  text-gray-900 `}
              >
                Previous
              </button>
              <button className="flex items-center justify-center space-x-2 rounded-lg px-2.5 py-1.5 text-center text-base font-medium text-gray-900 focus:outline-none bg-white border border-gray-200 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-100 w-10">
                {currentPage}
              </button>
              <button
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage((prev) => prev + 1)}
                className={`flex items-center justify-center space-x-2 rounded-lg p-2.5 text-center text-sm font-medium focus:outline-none ${
                  currentPage === totalPages
                    ? "cursor-not-allowed hover:text-gray-300 opacity-50"
                    : "cursor-pointer hover:text-gray-500"
                }  text-gray-900 `}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Clients;
