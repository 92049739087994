import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const fundAPI = {
  all: async () =>
    axios.get(`${baseURL}/all-user-funds-total`).then((res) => res.data),
  single: async (idToSearch) =>
    axios.get(`${baseURL}/user-funds/${idToSearch}`).then((res) => res.data),
};
