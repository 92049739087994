import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";

const MoreOptions = ({
  editText,
  editLink,
  deleteText,
  deleteLink,
  totpText,
  totpLink,
  onOptionClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  console.log(editLink, "editLink");
  console.log(editText, "editText");
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onOptionClick(option);
    setIsOpen(false);
  };

  return (
    <div
      onMouseLeave={() => isOpen && setIsOpen(false)}
      className="flex items-center justify-center border-b border-gray-300 h-11 "
    >
      <button onClick={handleToggle} className=" focus:outline-none">
        {/* Three dots icon for options */}
        <BsThreeDotsVertical className="flex justify-center text-right" />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-40 mt-1 overflow-hidden bg-white border border-gray-300 rounded-md shadow-lg bottom-4">
          <div className="py-1" role="menu" aria-orientation="vertical">
            {editLink && (
              <Link to={editLink}>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                  role="menuitem"
                >
                  {editText}
                </div>
              </Link>
            )}
            {totpLink && (
              <Link to={totpLink}>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                  role="menuitem"
                >
                  {totpText}
                </div>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MoreOptions;
