import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewStocksWatchListRequest } from "../features/watchListSlice";
import notificationSoundPath from "../notification.wav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastNotification = () => {
  const dispatch = useDispatch();
  const newWatchList = useSelector((state) => state.watchList.newStocks);
  const [playNotification, setPlayNotification] = useState(false);

  console.log(newWatchList, "newWatchList");

  const fetchWatchList = () => {
    dispatch(getNewStocksWatchListRequest());
    setPlayNotification(true); // Set flag to play notification
  };

  useEffect(() => {
    fetchWatchList();
    const intervalId = setInterval(() => {
      fetchWatchList();
      setPlayNotification(true); // Set flag to play notification
    }, 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (playNotification && newWatchList && newWatchList.length > 0) {
      // Play notification sound when watchlist is updated
      const notificationSound = new Audio(notificationSoundPath);
      // Attempt to play audio in response to a user interaction
      notificationSound
        .play()
        .then(() => {
          newWatchList.forEach((element) => {
            toast.success(element.stockName);
          });
          // Reset the flag after playing the notification
          setPlayNotification(false);
        })
        .catch((error) => {
          console.error("Autoplay failed:", error);
          // Handle the error or provide a user interface to allow playback
        });
    }
  }, [playNotification, newWatchList]);

  return <ToastContainer />;
};

export default ToastNotification;

// <div
// class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
// role="alert"
// >
// <span class="font-medium">{one.stockName}</span>
// </div>
