import React, { useState } from "react";

const SymbolDropdown = ({ items, symbol, setSymbol, getNewSymbolPrice }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleSymbol = (value) => {
    setSymbol(value); // Set the symbol when the user selects an item
    if (value) {
      getNewSymbolPrice(value);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block w-full my-2 text-left">
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="text-gray-600 border w-full hover:border-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-between items-center relative"
        type="button"
      >
        {symbol ? symbol : "Select Symbol"}
        <svg
          className={`w-2.5 h-2.5 ms-3 transform ${isOpen ? "rotate-180" : ""}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {/* Dropdown menu */}
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } z-50 bg-white divide-y w-full max-h-32 overflow-auto divide-gray-100 rounded-lg shadow absolute`}
      >
        <ul
          className="py-2 text-sm text-gray-700"
          aria-labelledby="dropdownDefaultButton"
        >
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <li
                onClick={() => toggleSymbol(item)} // Pass the item value to toggleSymbol
                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                key={index}
              >
                {item}
              </li>
            ))}
          {items && items.length === 0 && (
            <li
              onClick={toggleDropdown}
              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              Please add Chartink Link
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SymbolDropdown;
