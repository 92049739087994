import React, { useEffect } from "react";
import { getAllUsersHoldingRequest } from "../../features/holdingSlice";
import { useSelector, useDispatch } from "react-redux";
import Searchable from "../../components/table/SearchableTable";

const headers = [
  { property: "name", name: "Client Name" },
  { property: "pl", name: "Profit/Loss" },
  { property: "symbol", name: "Symbol" },
  { property: "holdingType", name: "Type" },
  { property: "remainingQuantity", name: "Quantity" },
  { property: "costPrice", name: "Cost Price/Stock" },
];

const AllHoldings = () => {
  const dispatch = useDispatch();
  const holdings = useSelector((state) => state.holdings.allHoldings);

  useEffect(() => {
    dispatch(getAllUsersHoldingRequest());
  }, [dispatch]);
  return (
    <div>
      <h1 className="px-4 py-2 mb-2 font-semibold text-gray-800">
        All Holdings ({holdings?.length})
      </h1>
      <Searchable
        tableHeaders={headers}
        tableData={holdings}
        defaultTableRow={2}
        searchBy="symbol"
        showSearch
        tableWidth="max-w-7xl"
      />
    </div>
  );
};

export default AllHoldings;
