import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserPositionsRequest } from "../../../features/positionSlice";
import { getAllUsersHoldingRequest } from "../../../features/holdingSlice";
import { getAllUserOrderRequest } from "../../../features/orderSlice";
import { getAllFundsRequest } from "../../../features/fundSlice";
import { formatMoney, sumProperty } from "../../../helpers";

const Statistics = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.admin.adminDetails);
  const positions = useSelector((state) => state.positions.allPositions);
  const holdings = useSelector((state) => state.holdings.allHoldings);
  const allOrders = useSelector((state) => state.orders.allOrders);
  const allFunds = useSelector((state) => state.funds.allFunds);

  useEffect(() => {
    dispatch(getAllUserPositionsRequest());
    dispatch(getAllUsersHoldingRequest());
    dispatch(getAllUserOrderRequest());
    dispatch(getAllFundsRequest());
  }, [dispatch]);
  return (
    <div className="px-6 py-2 mb-4 space-y-3 bg-white border border-gray-200 rounded-lg hover:cursor-pointer hover:border-cool-gray-900">
      <div className="flex flex-col flex-wrap md:flex-row md:flex-nowrap">
        <div className="flex justify-between w-full p-4 pl-0 border-b md:block md:w-1/4 md:border-b-0">
          <div className="text-base font-medium">Tradable Balance</div>

          <div className="inline-block px-4 py-1 text-sm text-white bg-green-600 rounded-lg active md:mt-4">
            {allFunds?.funds && `₹ ${formatMoney(allFunds?.funds)}`}
          </div>
        </div>
        <div className="flex justify-between w-full p-4 pl-0 border-b md:block md:w-1/4 md:border-b-0">
          <div className="text-base font-medium">Total Positions</div>
          <div className="inline-block px-4 py-1 text-sm text-white bg-indigo-600 rounded-lg active md:mt-4">
            ₹ {formatMoney(sumProperty(positions, "pl"))}
          </div>
        </div>
        <div className="flex justify-between w-full p-4 pl-0 border-b md:block md:w-1/4 md:border-b-0">
          <div className="text-base font-medium">Total Holdings</div>
          <div className="inline-block px-4 py-1 text-sm text-white bg-orange-600 rounded-lg active md:mt-4">
            ₹ {formatMoney(Math.floor(sumProperty(holdings, "pl")))}
          </div>
        </div>
        <div className="flex justify-between w-full p-4 pl-0 border-b md:block md:w-1/4 md:border-b-0">
          <div className="text-base font-medium">Total Orders</div>
          <div className="inline-block px-4 py-1 text-sm text-white bg-black rounded-lg active md:mt-4">
            ₹{" "}
            {formatMoney(
              Math.floor(
                sumProperty(allOrders, "tradedPrice") +
                  sumProperty(allOrders, "qty")
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
